import React, {useState} from "react"
import styles from "../ReportStyles.module.scss"
import {useTranslation} from "react-i18next"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import {RiskReportData} from "./riskReportData"
import {RiskProbabilityHeatMap} from "./RiskProbabilityHeatMap"
import RisksTable from "./RisksTable"
import {ClickAwayListener} from "@mui/material"

interface Props {
    data: RiskReportData
}

const RiskReportMainContent: React.FC<Props> = ({data}) => {
    const {t} = useTranslation("translations")

    const [selectedCell, setSelectedCell] = useState<{damage_impact: string; probability: string; id: string} | null>(null)
    const openRisks = data.getRiskByStatus(["Open", "In Progress"])

    const filteredData = openRisks.filter(
        (risk) => risk.damage_impact === selectedCell?.damage_impact && risk.probability === selectedCell.probability
    )

    const riskTableData = selectedCell ? filteredData : data.risks()

    return (
        <div className={styles.reportScreenVersion}>
            <Box sx={{flexGrow: 1}} className={`${styles.portfolioReportArea}`}>
                <Grid item container xs={12} spacing={1} className={`${styles.gridContainerSection}`} style={{margin: "5px 0px"}}>
                    <Grid item container xs={12} className={styles.topLineOverview}>
                        <Grid item xs={4} sx={{textAlign: "left"}}>
                            <div className={styles.reportHeader}>
                                <h3>{t("risks")} </h3>
                                <div className={styles.lineDecoration}></div>
                            </div>
                        </Grid>

                        <Grid item xs={8} className={styles.activeReportsWrapper}>
                            <p>
                                <span className={styles.activeReportText}>{t("active-programs")}:</span>
                                <span className={styles.activeReportNumber}>{data.numberOfPrograms()}</span>
                            </p>
                            <p>
                                <span className={styles.activeReportText}>{t("active-projects")}:</span>
                                <span className={styles.activeReportNumber}>{data.numberOfProjects()}</span>
                            </p>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <p className={styles.descriptionText}>{t("risks-report-overview-description-text-1")}</p>
                        <p className={styles.descriptionText}>{t("risks-report-overview-description-text-2")}</p>
                        <p className={styles.descriptionText}>{t("risks-report-overview-description-text-3")}</p>
                    </Grid>
                    <ClickAwayListener onClickAway={() => setSelectedCell(null)}>
                        <Grid item xs={12}>
                            <div style={{height: "500px", paddingBottom: "30px"}} className={`${styles.gridItem}`}>
                                <h5>{t("risk-report-damage_impact_probability_chart")}</h5>
                                <RiskProbabilityHeatMap data={openRisks} onCellClick={setSelectedCell} selectedCell={selectedCell} />
                            </div>
                        </Grid>
                    </ClickAwayListener>

                    {/* RISK TABLE*/}
                    <Grid item xs={12}>
                        <RisksTable risks={riskTableData} />
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default RiskReportMainContent
